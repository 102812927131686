import { QuickViewMode } from 'utils/enums';

export enum QuickViewModule {
  ORDERS = 'orders',
  ASN = 'asn',
  INVENTORY = 'inventory',
  USERS = 'users',
  PRODUCTS = 'products',
  SALES_CHANNEL = 'channel',
  INTEGRATIONS = 'integrations',
}

export interface QuickViewStackItem {
  module: QuickViewModule;
  mode: QuickViewMode;
  identifier?: string;
  tab?: string;
  onQuickViewNavigation?: (direction: 'prev' | 'next', id: string) => void;
  extraProps?: Record<string, unknown>;
}
