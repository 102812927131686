import { ApolloProvider } from '@apollo/client';
import NextProgress from 'next-progress';
import { Provider } from 'react-redux';

import { LayoutResolver } from 'components/Layout';

import '../styles/globals.css';

import { QuickViewProvider } from 'context/QuickViewContext';
import { TrackingProvider } from 'context/TrackingContext';
import { store } from 'store';

import client from '../api/client';

function App(props) {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <TrackingProvider>
          <QuickViewProvider>
            <NextProgress delay={300} options={{ showSpinner: false }} />
            <LayoutResolver {...props} />
          </QuickViewProvider>
        </TrackingProvider>
      </Provider>
    </ApolloProvider>
  );
}

export default App;
