import { FunctionComponent, useEffect, useId, useState } from 'react';

interface CheckInputProps {
  /**
   * Title of label
   */
  title?: string;
  /**
   * Value of checkbox when is selected
   */
  value: string | number;
  isChecked?: boolean;
  isOneSelectedOnly?: boolean;
  onChange?(value: string, isChecked: boolean): void;
}

export const CheckInput: FunctionComponent<CheckInputProps> = ({
  isChecked = false,
  isOneSelectedOnly = false,
  title,
  value,
  onChange,
}: CheckInputProps) => {
  const [checked, setChecked] = useState(isChecked);
  const [id] = useState(useId());

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const handleChange = e => {
    if (e?.target?.value) {
      if (isOneSelectedOnly && checked) {
        return;
      }
      onChange(e.target.value, !checked);
      setChecked(!checked);
    }
  };

  return (
    <div onClick={handleChange} className="flex justify-start items-center">
      {title ? (
        <>
          <input
            id={value + id}
            type="checkbox"
            className="cursor-pointer hidden"
            title={title}
            checked={checked}
            value={value}
          />
        </>
      ) : null}
      <label
        className={`select-none ${
          checked ? 'text-primary-60' : 'text-icons'
        } text-sm font-aeonik cursor-pointer flex items-center h-[50px]`}
        htmlFor={value + id}
      >
        {title}
      </label>
    </div>
  );
};
