import React, { createContext, useContext, useState } from 'react';

import { QuickViewStackItem } from 'types/quickview';

interface QuickViewContextProps {
  stack: QuickViewStackItem[];
  openQuickView: (item: QuickViewStackItem) => void;
  closeCurrentQuickView: () => void;
  closeAllQuickViews: () => void;
  replaceTopQuickView: (newItem: QuickViewStackItem) => void;
}

const QuickViewContext = createContext<QuickViewContextProps>(
  {} as QuickViewContextProps
);

export const QuickViewProvider = ({ children }) => {
  const [stack, setStack] = useState<QuickViewStackItem[]>([]);

  const openQuickView = (item: QuickViewStackItem) => {
    setStack(prev => [...prev, item]);
  };

  const closeCurrentQuickView = () => {
    setStack(prev => prev.slice(0, -1));
  };

  const closeAllQuickViews = () => {
    setStack([]);
  };

  const replaceTopQuickView = (newItem: QuickViewStackItem) => {
    setStack(prev => {
      if (prev.length === 0) return prev;
      const newStack = [...prev];
      newStack.pop();
      newStack.push(newItem);
      return newStack;
    });
  };

  return (
    <QuickViewContext.Provider
      value={{
        stack,
        openQuickView,
        closeCurrentQuickView,
        closeAllQuickViews,
        replaceTopQuickView,
      }}
    >
      {children}
    </QuickViewContext.Provider>
  );
};

export const useQuickView = () => useContext(QuickViewContext);
