import { FunctionComponent, ReactNode, useRef, useState } from 'react';

import RoundedButton from 'components/RoundedButton';

import { useOnClickOutside } from 'hooks';

import ArrowDown from 'assets/icons/arrow-down.svg';

type Option = {
  id?: string;
  label: string;
  action: () => void;
  icon?: ReactNode;
};

interface DropdownButton {
  children: ReactNode;
  isDisabled: boolean;
  options: Option[];
}

const DropdownButton: FunctionComponent<DropdownButton> = ({
  children,
  isDisabled,
  options = [],
}) => {
  const wrapperRef = useRef(undefined);
  const [isActive, setActive] = useState(() => false);
  useOnClickOutside(wrapperRef, () => setActive(false));

  return (
    <div
      className="relative inline-block"
      ref={wrapperRef}
    >
      <RoundedButton
        className="px-5 py-3 rounded-md shadow-button-primary"
        onClick={() => setActive(!isActive)}
      >
        <div className="flex fill-current text-white items-center justify-items-center">
          <span className="font-medium text-sm">{children}</span>
          <ArrowDown
            className={`ml-2 scale-50 origin-center transition duration-300 transform ${
              isActive ? 'rotate-180 fill-current' : '-rotate-80 fill-inactive'
            }`}
          />
        </div>
      </RoundedButton>
      <div
        className={`${
          isActive ? 'block' : 'hidden'
        } absolute bg-white origin-top right-0 mt-1.5 w-48 rounded-lg-.5 shadow-bs-tertiary z-40`}
      >
        <div className="m-1 rounded-md ring-1 ring-black ring-opacity-5">
          {options.map(option => (
            <div
              id={option?.id}
              key={option.label}
              onClick={() => {
                option.action();
                setActive(false);
              }}
              className={`${
                isDisabled && option.label === 'Bulk'
                  ? 'opacity-60 pointer-events-none'
                  : ''
              } flex items-center text-body-14 text-mvs-text-primary  cursor-pointer select-none px-5 py-2 hover:bg-gray-50 hover:rounded-md`}
            >
              {option.icon && <div className="mr-2">{option.icon}</div>}
              {option.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropdownButton;
