import dynamic from 'next/dynamic';

import { QuickViewModule } from 'types/quickview';

export const QuickViewsMap = {
  [QuickViewModule.ORDERS]: dynamic(
    () => import('components/QuickView/orders')
  ),
  [QuickViewModule.ASN]: dynamic(() => import('components/QuickView/asn')),
  [QuickViewModule.INVENTORY]: dynamic(
    () => import('components/QuickView/inventory')
  ),
  [QuickViewModule.PRODUCTS]: dynamic(
    () => import('components/QuickView/catalog')
  ),
  [QuickViewModule.SALES_CHANNEL]: dynamic(
    () => import('components/QuickView/sales-channel')
  ),
  [QuickViewModule.INTEGRATIONS]: dynamic(
    () => import('components/QuickView/integrations')
  ),
  [QuickViewModule.USERS]: dynamic(() => import('components/QuickView/user')),
};
