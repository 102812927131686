import { AnimatePresence } from 'framer-motion';
import { FunctionComponent, useEffect } from 'react';

import { Header, Navbar, Spinner } from 'components';

import { useQuickView } from 'context/QuickViewContext';
import { useAppSelector, useAuthentication } from 'hooks';

import { QuickViewsMap } from 'utils/constants/quickView/quickviewMap';

import { FeatureFlag } from 'types/featureFlags';

interface LayoutProps {
  children?: React.ReactNode;
  featureFlags: FeatureFlag[];
  pageProps?: Record<string, unknown>;
}

const MainLayout: FunctionComponent<LayoutProps> = ({
  children,
  featureFlags,
}) => {
  const { stack, closeCurrentQuickView } = useQuickView();
  const activeQuickView = stack[stack.length - 1];

  let QuickViewComponent: JSX.Element | null = null;

  const sessionLoading = useAppSelector(state => state.session.sessionLoading);
  const menuItems = useAppSelector(state => state.config.menu);

  const { handleValidateSession } = useAuthentication({
    featureFlags,
  });

  useEffect(() => {
    handleValidateSession();
  }, []);

  if (sessionLoading) {
    return (
      <div className="flex w-full h-screen items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (activeQuickView) {
    const Component = QuickViewsMap[activeQuickView.module];
    if (Component) {
      QuickViewComponent = (
        <Component
          identifier={activeQuickView.identifier}
          quickViewMode={activeQuickView.mode}
          handleOutsideClick={closeCurrentQuickView}
          onQuickViewNavigation={activeQuickView?.onQuickViewNavigation}
          {...activeQuickView.extraProps}
        />
      );
    }
  }

  return (
    <div className="h-screen w-screen">
      <Header />
      <div className="flex h-content-container w-screen relative">
        <Navbar items={menuItems} />
        <main className="flex flex-1 flex-col w-screen pb-0 overflow-auto px-7 pt-7 bg-gray-50">
          {children}
        </main>
        <AnimatePresence>{QuickViewComponent}</AnimatePresence>
      </div>
    </div>
  );
};

export default MainLayout;
