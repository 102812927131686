import { AnimatePresence, motion } from 'framer-motion';
import { CSSProperties, FunctionComponent, useState } from 'react';
import { ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';

type ToolTipPreset = 'light' | 'dark';

type ToolTipProps = ITooltip & {
  preset?: ToolTipPreset;
};

const DEFAULT_STYLES: CSSProperties = {
  padding: 0,
  fontSize: 'unset',
  zIndex: 11,
  opacity: 1,
  border: 'none',
};

const TOOLTIP_PRESETS: Record<ToolTipPreset, CSSProperties> = {
  light: {
    ...DEFAULT_STYLES,
  },
  dark: {
    ...DEFAULT_STYLES,
    backgroundColor: '#212121',
    borderRadius: 8,
    fontSize: 13,
  },
};

const Tooltip: FunctionComponent<ToolTipProps> = (props: ToolTipProps) => {
  const preset = TOOLTIP_PRESETS[props.preset ?? 'light'] || DEFAULT_STYLES;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <ReactTooltip
      {...props}
      style={preset}
      afterShow={() => setIsVisible(true)}
      afterHide={() => setIsVisible(false)}
    >
      <AnimatePresence>
        {isVisible && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9  }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9}}
            transition={{ duration: 0.2 }}
          >
            {props.children}
          </motion.div>
        )}
      </AnimatePresence>
    </ReactTooltip>
  );
};

export default Tooltip;